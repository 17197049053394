.footerSection {
  padding-bottom: 20px;
  background-color: aliceblue;
}

.footer {
  margin-top: 45px;
}

.footerContent {
  display: flex;
  justify-content: space-between;
}

.footerLeft {
  text-align: left;
}

.footerHeader {
  width: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.footerParagraph {
  width: 466px;
  height: 116px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #4d4d4d;
  margin-top: 12px;
}

.formTopSection {
  display: flex;
}

.formTopSection > div > label,
.formBottomSection > label {
  display: block;
  margin-bottom: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.6);
}

.formTopSection > div > input,
.formBottomSection > textarea {
  width: 267px;
  height: 45px;
  padding: 0px 10px;
  background-color: aliceblue;
  border: 2px solid #bbc8d4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 12px;
}

.emailInput {
  margin-left: 30px;
}

.formBottomSection {
  margin-top: 40px;
}

.formBottomSection > textarea {
  display: block;
  width: 100%;
  height: 250px;
  padding-top: 12px;
}

.formButton {
  margin-top: 30px;
  border-radius: 10px;
  width: 130px;
  height: 42px;
  border-radius: 4px;
  font-style: normal;
  font-size: 12px;
  background-color: lightgrey;
  opacity: 40%;
}

.formButton:hover {
  box-shadow: none !important;
  cursor: auto;
}

.copyright {
  height: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(37, 49, 60, 0.5);
}

.footerIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 135px;
}

.linkIcons {
  display: flex;
  align-items: center;
}

.githubIcon {
  margin-right: 5px;
}

/* Media Query */

@media only screen and (max-width: 1100px) {
  .footerContent {
    flex-direction: column;
  }

  .footerLeft {
    margin-bottom: 17px;
  }

  .formBottomSection > textarea {
    height: 165px;
  }
  .formTopSection > div > input,
  .formBottomSection > textarea {
    width: 100%;
  }

  .footer {
    margin-top: 15px;
  }
}

/* Mobile Portrait */

@media only screen and (max-width: 478px) and (orientation: portrait) {
  .footerHeader {
    font-size: 21px;
    line-height: 28px;
  }

  .footerRight {
    display: none;
  }

  .footerParagraph {
    width: auto;
    height: auto;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  .formTopSection {
    flex-direction: column;
  }

  .emailInput {
    margin-left: 0px;
    margin-top: 12px;
  }

  .footerIcons {
    margin-top: 60px;
  }

  .copyright {
    font-size: 7px;
  }
}
