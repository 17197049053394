:root {
  /* Primary colors */
  --clr-primary-1: #bbc8d4;
  --clr-primary-2: #000000;

  /* Dark shades of paragraph text */
  --clr-primary-3: #414141;
  --clr-primary-4: #82a1ff;

  /*Orange color of buttoms and span texts */
  --clr-primary-5: #ffcd00;

  /* Hero Section background and text */
  --clr-primary-6: #f5f5ff;
  --clr-primary-7: #211f1f;

  /* Other */
  --clr-primary-8: #6b90ff;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.section {
  padding: 30px 60px 90px 60px;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
}

li {
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #414141;
  color: var(--clr-primary-3);
  list-style: none;
}

li:hover {
  cursor: pointer;
  color: #3d3d3dcc;
}

h1 {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #211f1f;
  color: var(--clr-primary-7);
  margin-bottom: 10px;
  font-family: "Abril Fatface", cursive;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: #000000;
  color: var(--clr-primary-2);
  font-family: "Abril Fatface", cursive;
}

h3 {
  width: 222px;
  height: 118px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  color: var(--clr-primary-2);
}

h4 {
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
  color: var(--clr-primary-2);
}

h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
}

h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.1em;
  margin-left: 10px;
}

button {
  background: #ffcd00;
  background: var(--clr-primary-5);
  color: #000000;
  width: 129px;
  height: 47px;
  font-weight: 600;
  border-radius: 33px;
  border: transparent;
  font-family: Poppins;
  transition: all ease-in-out 250ms;
}

button:hover {
  cursor: pointer;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.459);
}

span {
  color: #ffcd00;
  color: var(--clr-primary-5);
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #414141;
  color: var(--clr-primary-3);
}

.hero_image {
  width: 65px;
  height: 65px;
  margin-bottom: 12px;
}

.hero_myName {
  width: 293px;
  height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.hero_paragraph {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #414141;
  color: var(--clr-primary-3);
  width: 500px;
  height: 60px;
  margin-bottom: 50px;
}

.btn {
  font-family: Poppins;
  width: 200px;
  height: 56px;
  background: #414141;
  background: var(--clr-primary-3);
  color: black;
  border-radius: 10px;
}

.service_Box {
  width: 305px;
  height: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Gradient */
  background: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);
  /* Shadow */
  box-shadow: 0px 7px 12px 1px rgba(0, 0, 0, 0.42);
  border-radius: 10px;
}

.service_Box > img {
  width: 45px;
  height: 45px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.caption {
  width: 237px;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 12px;
}

/* Media Queries */

/* Below Desktop view */

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .service_Box {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .section {
    padding: 30px;
  }

  button {
    width: 115px;
    height: 43px;
  }

  .service_Box {
    width: 100%;
    height: 210px;
    margin-bottom: 37px;
    box-shadow: 0 7px 12px 1px rgb(0 0 0 / 20%);
  }

  .caption {
    width: 100%;
    padding: 0px 20px;
  }
}

/* Mobile Portrait */

@media only screen and (max-width: 478px) and (orientation: portrait) {
  h1 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .hero_paragraph {
    font-size: 15px;
    line-height: 24px;
    width: auto;
    height: auto;
    margin-bottom: 40px;
  }

  li {
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0px;
  }

  button {
    width: 105px;
    height: 41px;
    font-size: 12px;
  }

  h2 {
    font-size: 21px;
    line-height: 28px;
  }

  p {
    font-size: 14px;
    line-height: 23px;
  }

  .hero_myName {
    margin-bottom: 20px;
    width: 100% !important;
  }

  .caption {
    width: 100%;
    padding: 0px 12px;
  }

  .service_Box {
    height: 270px;
  }

  .service_Box > img {
    margin-top: 31px;
  }
}

@media only screen and (max-width: 403px) and (orientation: portrait) {
  .service_Box > img {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
  .service_Box {
    height: 287px;
  }
}

.footerSection {
  padding-bottom: 20px;
  background-color: aliceblue;
}

.footer {
  margin-top: 45px;
}

.footerContent {
  display: flex;
  justify-content: space-between;
}

.footerLeft {
  text-align: left;
}

.footerHeader {
  width: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.footerParagraph {
  width: 466px;
  height: 116px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #4d4d4d;
  margin-top: 12px;
}

.formTopSection {
  display: flex;
}

.formTopSection > div > label,
.formBottomSection > label {
  display: block;
  margin-bottom: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.6);
}

.formTopSection > div > input,
.formBottomSection > textarea {
  width: 267px;
  height: 45px;
  padding: 0px 10px;
  background-color: aliceblue;
  border: 2px solid #bbc8d4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 12px;
}

.emailInput {
  margin-left: 30px;
}

.formBottomSection {
  margin-top: 40px;
}

.formBottomSection > textarea {
  display: block;
  width: 100%;
  height: 250px;
  padding-top: 12px;
}

.formButton {
  margin-top: 30px;
  border-radius: 10px;
  width: 130px;
  height: 42px;
  border-radius: 4px;
  font-style: normal;
  font-size: 12px;
  background-color: lightgrey;
  opacity: 40%;
}

.formButton:hover {
  box-shadow: none !important;
  cursor: auto;
}

.copyright {
  height: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(37, 49, 60, 0.5);
}

.footerIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 135px;
}

.linkIcons {
  display: flex;
  align-items: center;
}

.githubIcon {
  margin-right: 5px;
}

/* Media Query */

@media only screen and (max-width: 1100px) {
  .footerContent {
    flex-direction: column;
  }

  .footerLeft {
    margin-bottom: 17px;
  }

  .formBottomSection > textarea {
    height: 165px;
  }
  .formTopSection > div > input,
  .formBottomSection > textarea {
    width: 100%;
  }

  .footer {
    margin-top: 15px;
  }
}

/* Mobile Portrait */

@media only screen and (max-width: 478px) and (orientation: portrait) {
  .footerHeader {
    font-size: 21px;
    line-height: 28px;
  }

  .footerRight {
    display: none;
  }

  .footerParagraph {
    width: auto;
    height: auto;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  .formTopSection {
    flex-direction: column;
  }

  .emailInput {
    margin-left: 0px;
    margin-top: 12px;
  }

  .footerIcons {
    margin-top: 60px;
  }

  .copyright {
    font-size: 7px;
  }
}

.email {
  display: flex;
  align-items: center;
}

.logo > img {
  height: 37px;
}

.logo > img:hover {
  cursor: pointer;
}

.mywork {
  margin-right: 30px;
}

.navSection {
  margin-top: 12px;
}

.navbar {
  width: 100%;
  background-color: transparent;
  transition: all 250ms ease-in-out;
}

.navbar__Container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.navbar__Links {
  display: flex;
}

.menu {
  display: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 700;
  transition: all ease-in-out 250ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu:focus,
.menu:active {
  outline: none !important;
  box-shadow: none !important;
}

/* =================== */
/*   Media Queries */
/* =================== */

@media screen and (max-width: 478px) {
  .menu {
    display: flex;
    border: none;
    align-items: center;
    color: black;
    width: auto;
  }

  .navbar__Links {
    display: none;
  }

  .navbar__mobileLinks {
    display: flex;
    padding: 0;
    position: absolute;
    list-style: none;
    flex-direction: column;
    top: 111%;
    right: 0%;
    width: 100%;
    font-size: 13px;
    background-color: rgba(11, 11, 11, 0.82);
    padding: 10px 0px;
    text-align: center;
    border-radius: 4px;
  }

  .mywork {
    margin-right: 0px;
  }

  .navbar__mobileLinks > li {
    padding: 6px 10px;
    color: black;
    font-size: 13px;
  }
}

.hero__Section {
  background-color: var(--clr-primary-6);
  height: 615px;
  width: 97vw;
  margin: 0 auto;
  padding: 0px 44px;
  margin-top: 10px;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.hero_paragraph {
  margin-top: 10px;
}

.hero__Span {
  color: #003fff9c;
}

/* Media Query */

@media only screen and (max-width: 991px) {
  .hero__Section {
    padding: 0px 17px;
    margin-top: 11px;
    height: 565px;
  }

  .hero__Content {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 478px) and (orientation: portrait) {
  .hero__Section {
    height: 515px;
  }
}

.services {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.services__Paragraph {
  width: 432px;
  height: 87px;
  margin-top: 12px;
}

.services__Row {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

/* Media Query */

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .lastBox {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .services {
    margin-top: 30px;
  }

  .services__Row {
    flex-direction: column;
  }

  .lastBox {
    height: 200px;
  }
}

/* Mobile portrait */

@media only screen and (max-width: 478px) and (orientation: portrait) {
  .services__Paragraph {
    width: auto;
  }

  .lastBox {
    height: 265px;
  }
}

.work {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.workHeading {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 30px;
}

.project__Row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 110px;
}

.leftBlock > h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
}

.leftBlock > p {
  width: 577px;
  height: 118px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #4d4d4d;
  margin-bottom: 60px;
}

.leftBlock > a > button {
  width: 130px;
  height: 42px;
  border-radius: 4px;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
}

.techRow {
  display: flex;
  margin-top: 12px;
  margin-bottom: 25px;
}

.techRow > button {
  width: auto;
  height: 29px;
  background: #c4c4c4;
  border-radius: 0px;
  padding: 8px 12px;
  margin-right: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150.6%;
  text-align: center;
  color: #000000;
}

.techRow > button:hover {
  box-shadow: none;
  cursor: default;
}

.rightBlock > img {
  height: 380px;
}

.second {
  flex-direction: row-reverse;
}

.last {
  margin-bottom: 0px;
}

/* Media Query */

@media only screen and (max-width: 1090px) {
  .project__Row {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-bottom: 60px;
  }

  .project__Row:last-child {
    margin-bottom: 0px;
  }

  .workHeading {
    margin-bottom: 15px;
  }

  .rightBlock > img {
    height: 350px;
    margin-left: -16px;
  }

  .leftBlock > p {
    width: auto;
    margin-bottom: 0px;
  }
  .techRow > button {
    height: auto;
    font-size: 9px;
  }
}

/* Mobile Portrait */

@media only screen and (max-width: 478px) and (orientation: portrait) {
  .rightBlock > img {
    width: auto;
    height: 200px;
  }

  .leftBlock > p {
    width: auto;
    height: auto;
    font-size: 14px;
    line-height: 23px;
  }

  .leftBlock > h2 {
    font-size: 21px;
    line-height: 28px;
  }

  .leftBlock > a > button {
    width: 112px;
    height: 40px;
    margin-top: 30px;
  }
}

.topButton {
  display: none;
  position: fixed;
  right: 5px;
  bottom: 5px;
  -webkit-animation: myanimation 2s infinite;
          animation: myanimation 2s infinite;
  border-radius: 120px;
}

.topButton.active {
  display: flex;
  align-content: center;
  box-shadow: 0px 6px 9px -5px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0px 6px 9px -5px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 6px 9px -5px rgba(0, 0, 0, 0.7);
}

.topButton:hover {
  cursor: pointer;
}

.arrowIcon {
  width: 37px;
  height: 37px;
}

@-webkit-keyframes myanimation {
  75% {
    bottom: 25px;
  }
}

@keyframes myanimation {
  75% {
    bottom: 25px;
  }
}

@-webkit-keyframes mobileanimation {
  50% {
    bottom: 22px;
  }
  75% {
    bottom: 27px;
  }
}

@keyframes mobileanimation {
  50% {
    bottom: 22px;
  }
  75% {
    bottom: 27px;
  }
}

@media only screen and (max-width: 478px) {
  .arrowIcon {
    width: 37px;
    height: 37px;
  }
}

