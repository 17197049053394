.work {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.workHeading {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 30px;
}

.project__Row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 110px;
}

.leftBlock > h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
}

.leftBlock > p {
  width: 577px;
  height: 118px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #4d4d4d;
  margin-bottom: 60px;
}

.leftBlock > a > button {
  width: 130px;
  height: 42px;
  border-radius: 4px;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
}

.techRow {
  display: flex;
  margin-top: 12px;
  margin-bottom: 25px;
}

.techRow > button {
  width: auto;
  height: 29px;
  background: #c4c4c4;
  border-radius: 0px;
  padding: 8px 12px;
  margin-right: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150.6%;
  text-align: center;
  color: #000000;
}

.techRow > button:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

.rightBlock > img {
  height: 380px;
}

.second {
  flex-direction: row-reverse;
}

.last {
  margin-bottom: 0px;
}

/* Media Query */

@media only screen and (max-width: 1090px) {
  .project__Row {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-bottom: 60px;
  }

  .project__Row:last-child {
    margin-bottom: 0px;
  }

  .workHeading {
    margin-bottom: 15px;
  }

  .rightBlock > img {
    height: 350px;
    margin-left: -16px;
  }

  .leftBlock > p {
    width: auto;
    margin-bottom: 0px;
  }
  .techRow > button {
    height: auto;
    font-size: 9px;
  }
}

/* Mobile Portrait */

@media only screen and (max-width: 478px) and (orientation: portrait) {
  .rightBlock > img {
    width: auto;
    height: 200px;
  }

  .leftBlock > p {
    width: auto;
    height: auto;
    font-size: 14px;
    line-height: 23px;
  }

  .leftBlock > h2 {
    font-size: 21px;
    line-height: 28px;
  }

  .leftBlock > a > button {
    width: 112px;
    height: 40px;
    margin-top: 30px;
  }
}
