:root {
  /* Primary colors */
  --clr-primary-1: #bbc8d4;
  --clr-primary-2: #000000;

  /* Dark shades of paragraph text */
  --clr-primary-3: #414141;
  --clr-primary-4: #82a1ff;

  /*Orange color of buttoms and span texts */
  --clr-primary-5: #ffcd00;

  /* Hero Section background and text */
  --clr-primary-6: #f5f5ff;
  --clr-primary-7: #211f1f;

  /* Other */
  --clr-primary-8: #6b90ff;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.section {
  padding: 30px 60px 90px 60px;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
}

li {
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: var(--clr-primary-3);
  list-style: none;
}

li:hover {
  cursor: pointer;
  color: #3d3d3dcc;
}

h1 {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: var(--clr-primary-7);
  margin-bottom: 10px;
  font-family: "Abril Fatface", cursive;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: var(--clr-primary-2);
  font-family: "Abril Fatface", cursive;
}

h3 {
  width: 222px;
  height: 118px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: var(--clr-primary-2);
}

h4 {
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--clr-primary-2);
}

h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
}

h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.1em;
  margin-left: 10px;
}

button {
  background: var(--clr-primary-5);
  color: #000000;
  width: 129px;
  height: 47px;
  font-weight: 600;
  border-radius: 33px;
  border: transparent;
  font-family: Poppins;
  transition: all ease-in-out 250ms;
}

button:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.596);
  -moz-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.418);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.459);
}

span {
  color: var(--clr-primary-5);
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: var(--clr-primary-3);
}

.hero_image {
  width: 65px;
  height: 65px;
  margin-bottom: 12px;
}

.hero_myName {
  width: 293px;
  height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.hero_paragraph {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: var(--clr-primary-3);
  width: 500px;
  height: 60px;
  margin-bottom: 50px;
}

.btn {
  font-family: Poppins;
  width: 200px;
  height: 56px;
  background: var(--clr-primary-3);
  color: black;
  border-radius: 10px;
}

.service_Box {
  width: 305px;
  height: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Gradient */
  background: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);
  /* Shadow */
  box-shadow: 0px 7px 12px 1px rgba(0, 0, 0, 0.42);
  border-radius: 10px;
}

.service_Box > img {
  width: 45px;
  height: 45px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.caption {
  width: 237px;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 12px;
}

/* Media Queries */

/* Below Desktop view */

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .service_Box {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .section {
    padding: 30px;
  }

  button {
    width: 115px;
    height: 43px;
  }

  .service_Box {
    width: 100%;
    height: 210px;
    margin-bottom: 37px;
    box-shadow: 0 7px 12px 1px rgb(0 0 0 / 20%);
  }

  .caption {
    width: 100%;
    padding: 0px 20px;
  }
}

/* Mobile Portrait */

@media only screen and (max-width: 478px) and (orientation: portrait) {
  h1 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .hero_paragraph {
    font-size: 15px;
    line-height: 24px;
    width: auto;
    height: auto;
    margin-bottom: 40px;
  }

  li {
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0px;
  }

  button {
    width: 105px;
    height: 41px;
    font-size: 12px;
  }

  h2 {
    font-size: 21px;
    line-height: 28px;
  }

  p {
    font-size: 14px;
    line-height: 23px;
  }

  .hero_myName {
    margin-bottom: 20px;
    width: 100% !important;
  }

  .caption {
    width: 100%;
    padding: 0px 12px;
  }

  .service_Box {
    height: 270px;
  }

  .service_Box > img {
    margin-top: 31px;
  }
}

@media only screen and (max-width: 403px) and (orientation: portrait) {
  .service_Box > img {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
  .service_Box {
    height: 287px;
  }
}
